// import React from "react";

import { useEffect } from "react";
import Footer from "../Common/Footer/Footer";
import Nav from "../Common/Navbar/Nav";
import PriceCardComponent from "../Components/PriceList/PriceCard";

const Pricing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Nav />
      <div className="mt-20 lg:mt-16 w-full mx-auto ">
        <>
          <div className="w-[100%] flex justify-center items-center ">
            <PriceCardComponent />
          </div>
        </>
      </div>
      <Footer />
    </>
  );
};

export default Pricing;
