import React from "react";
import googleplay from "../assets/PNG/googleplay.png";
import applestore from "../assets/PNG/applestore.png";
import gabbage from "../assets/PNG/gabbabge 1.png";
import { questions } from "../Constants/constants";
import Question from "../Components/Faqs/question";

const Faqs = () => {
  React.useEffect(() => {
    const element = document.getElementById("faq");
    element.scrollIntoView(0, 0);
  }, []);
  return (
    <>
      <div className="faq-container faq-div-1 p-2 sm:p-10 xl:p-16 w-full flex flex-col sm:flex-row gap-5">
        <div className="header sm:p-5 w-full sm:w-[50%] mt-8 flex flex-col items-start justify-center">
          <div className="r">
            <h1 className="font-bold text-[40px] sm:text-[50px]" id="faq">
              FAQs
            </h1>
            <p className="faq-para font-medium  text-[20px] sm:text-[22px] text-[#727272] -mt-2">
              Have questions? We're here to help
            </p>
          </div>
          <div className="faq-stores mt-5 flex flex-wrap gap-2">
            <img
              src={googleplay}
              alt=""
              className="w-[150px] h-[50px] cursor-pointer"
            />
            <img
              src={applestore}
              alt=""
              className="w-[150px] h-[50px] cursor-pointer"
            />
          </div>
        </div>
        <div className="header  w-[100%] sm:w-[50%] p-5 pt-0">
          <img src={gabbage} alt="" className="image h-[350px] w-full" />
        </div>
      </div>
      <div className="faqs-question mt-5 mb-16 w-full px-[2rem] sm:px-[5rem] xl:px-[5.25rem]">
        <ul className="list-disc">
          {questions.map((question, index) => (
            <Question key={index} question={question} />
          ))}
        </ul>
      </div>
    </>
  );
};

export default Faqs;
